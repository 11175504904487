import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {getCssSizeValue, getFontSize, getItemUrl} from "@/utils/utils";
import {BREAKPOINTS, colors} from "@/styles/themeSettings";
import Reveal from "@/animations/Reveal";
import {useRouter} from "next/router";
import useClickOutside from "@/hooks/useClickOutside";

const Tile = styled.div`
    background-color: #0E2C54;
    transition: background-color 0.3s ease-in-out;
    height: ${getCssSizeValue(150, BREAKPOINTS.MOBILE)};
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: ${getCssSizeValue(8, BREAKPOINTS.MOBILE)} ${getCssSizeValue(8, BREAKPOINTS.MOBILE)};
    overflow: hidden;

    p {
        color: ${({theme}) => theme.colors.white};
        position: relative;
        z-index: 1;
    }

    .text-bottom {
        position: absolute;
        //bottom: 0;
        //left: 0;
        left: 15px;
        bottom: 8px;
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            left: 22px;
            bottom: 14px;
        }
    }

    .arrow-red {
        position: absolute;
        right: 22px;
        bottom: 14px;
        z-index: 1;

        path {
            fill: ${({theme}) => theme.colors.chilliRed};
        }
    }

    p.title {
        color: ${({theme}) => theme.colors.white};
        font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(24, BREAKPOINTS.MOBILE)};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(24, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(36, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(36, BREAKPOINTS.DESKTOP)};
        }
    }

    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        overflow: initial;
        height: ${getCssSizeValue(325, BREAKPOINTS.TABLET)};
        padding: ${getCssSizeValue(16, BREAKPOINTS.TABLET)} ${getCssSizeValue(24, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            // height: ${getCssSizeValue(325, BREAKPOINTS.DESKTOP)}
        height: auto;
        aspect-ratio: 1;
        width: 100%;
        padding: ${getCssSizeValue(16, BREAKPOINTS.DESKTOP)} ${getCssSizeValue(24, BREAKPOINTS.DESKTOP)};

        &:hover {
            background-color: ${({theme}) => theme.colors.platinum};
            transition: background-color 0.3s ease-in-out;

            .text-bottom {
                display: none;
            }

            .tile-desc {
                opacity: 1;
                transform: translateY(0);
                transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
            }
			
			.double-description {
				opacity: 1;
				transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
			}

            .icon2 {
                    //width: ${getCssSizeValue(80, BREAKPOINTS.DESKTOP)};
                    //height: ${getCssSizeValue(80, BREAKPOINTS.DESKTOP)};
                opacity: 0;
                transition: transform 0.3s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out, opacity 0.1s ease-in-out;
                //transform-origin: right;
            }

            p.title {
                font-weight: ${({theme}) => theme.fontWeight.bold};
                transition: color 0.3s ease-in-out, font-weight 0.3s ease-in-out;
                color: ${({theme}) => theme.colors.chilliRed};
            }
        }
    }

    @media (min-width: 1921px) {
        height: auto;
        aspect-ratio: 1;
        width: 100%;
    }

	&.scrollable {
		overflow-y: auto;
		max-height: 100%;

        -ms-overflow-style: none;
        scrollbar-width: none;
		
		&::-webkit-scrollbar {
			display: none;
		}
	}
	
    &.hovered {
		background-color: ${({theme}) => theme.colors.platinum};
        transition: background-color 0.3s ease-in-out;

        .double-description {
            opacity: 1;
            transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
        }

        .text-bottom {
            display: none;
        }

        .tile-desc {
            opacity: 1;
            transform: translateY(0);
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
        }

        .icon2 {
                // width: ${getCssSizeValue(80, BREAKPOINTS.TABLET)};
                // height: ${getCssSizeValue(80, BREAKPOINTS.TABLET)};
            opacity: 0;
            transition: transform 0.3s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out, opacity 0.1s ease-in-out;
            //transform-origin: right;
        }

        .icon2-hover {
                // width: ${getCssSizeValue(80, BREAKPOINTS.TABLET)};
                // height: ${getCssSizeValue(80, BREAKPOINTS.TABLET)};
            transition: transform 0.3s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out, opacity 0.3s ease-in-out;
            //transform-origin: right;
            opacity: 0.7;
        }

        p.title {
            font-weight: ${({theme}) => theme.fontWeight.bold};
            transition: color 0.3s ease-in-out, font-weight 0.3s ease-in-out;
            color: ${({theme}) => theme.colors.chilliRed};
        }

        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px) {
        }
    }
`

const DoubleDescription = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 200%;
	width: 100%;
	opacity: 0;
	transition: transform 0.3s linear, opacity 0.3s ease-in-out;
	transform-origin: bottom;
	padding: ${getCssSizeValue(24, BREAKPOINTS.TABLET)};
	background-color: ${({theme}) => theme.colors.platinum};
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
		height: 100%;
		width: 200%;
		padding: ${getCssSizeValue(24, BREAKPOINTS.DESKTOP)};
	}
`

const IconWrapper = styled.div`
	//width: ${getCssSizeValue(140, BREAKPOINTS.DESKTOP)};
	position: absolute;
	top: ${getCssSizeValue(16, BREAKPOINTS.DESKTOP)};
	right: ${getCssSizeValue(16, BREAKPOINTS.DESKTOP)};
	//height: ${getCssSizeValue(140, BREAKPOINTS.DESKTOP)};
	z-index: 0;
	img {
		width: ${getCssSizeValue(140, BREAKPOINTS.DESKTOP)};
		height: ${getCssSizeValue(140, BREAKPOINTS.DESKTOP)};
		transition: transform 0.3s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out, opacity 0.3s ease-in-out;
		transform-origin: right;
		position: absolute;
	}
	img.icon2 {
		right: 0;
		opacity: 1;
		width: ${getCssSizeValue(65, BREAKPOINTS.MOBILE)};
		height: ${getCssSizeValue(65, BREAKPOINTS.MOBILE)};
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px) {
			width: ${getCssSizeValue(125, BREAKPOINTS.TABLET)};
			height: ${getCssSizeValue(125, BREAKPOINTS.TABLET)};
		}
		
	}
	img.icon2-hover {
		opacity: 0;
		right: 0;
	}
`

const Description = styled.div`
	opacity: 0;
	transform: translateY(10px);
	z-index: 1;
	transition: opacity 0.3s ease-in-out, transform 0.3s ease-out;
	@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
		display: block;
	}
	p {
		color: ${({theme}) => theme.colors.richBlack};
	}
`

const TileIconText = ({data, index, buttonData, isDesktop, isTablet, isMobile}) => {
	const router = useRouter();
	const icon = data?.icon?.url || '';
	const iconHover = data?.iconHover?.url || '';
	const iconAlt = data?.icon?.alt || '';
	const iconHoverAlt = data?.iconHover?.alt || '';
	const text = data?.text || '';
	const description = data?.description || '';
	const isBigOnHover = data?.isbigonhover || false;
	const mobileGridColumnStart = (index === 1 || index === 3 || index === 5 || index === 7) ? 1 : 2;
	const tabletGridColumnStart = (index === 1 || index === 4 || index === 7) ? 3 : (index === 2 || index === 5) ? 2 : index === 4 ? 2 : 1;
	const desktopGridColumnStart = (index === 1 || index === 5) ? 4 : (index === 2 || index === 6) ? 3 : index === 4 ? 2 : 5;
	const finalGridColumnStart = isMobile ? mobileGridColumnStart : isTablet ? tabletGridColumnStart : desktopGridColumnStart;
	const mobileGridColumnEnd = (index === 1 || index === 3 || index === 5 || index === 7) ? 2 : 3;
	const tabletGridColumnEnd = (index === 1 || index === 4 || index === 7) ? 4 : (index === 2 || index === 5) ? 3 : index === 4 ? 3 : 2;
	const desktopGridColumnEnd = (index === 1 || index === 5) ? 5 : (index === 2 || index === 6) ? 4 : index === 4 ? 3 : 6;
	const finalGridColumnEnd = isMobile ? mobileGridColumnEnd : isTablet ? tabletGridColumnEnd : desktopGridColumnEnd;
	const slug = getItemUrl(buttonData?.link?.link?.link || '');
	const [isTileHovered, setIsTileHovered] = useState(false)
	const TileRef = useRef();
	useClickOutside(TileRef, () => {
		setIsTileHovered(false);
	})
	const handleToggleTile = () => {
		setIsTileHovered(prev => !prev);
	}

	useEffect(() => {
		if (!isTileHovered && TileRef.current) {
			TileRef.current.scrollTop = 0;
		}
	}, [isTileHovered]);
	return (
		<Tile ref={TileRef}
			  hasLink={slug !== ''}
			  onClick={handleToggleTile}
			  // className={`${isTileHovered ? "col-desktop-1 hovered" : "col-desktop-1 col-mobile-2"} ${(!isDesktop && isTileHovered && !isBigOnHover) ? 'scrollable' : ''}`}
			  className={`${isTileHovered ? "col-desktop-1 hovered" : "col-desktop-1 col-mobile-2"} ${(!isDesktop && isTileHovered) ? 'scrollable' : ''}`}
			  style={{gridColumnStart: finalGridColumnStart, gridColumnEnd: finalGridColumnEnd}}>
			{!isBigOnHover && <Description className="tile-desc">
				<p className="typo-strawford typo-strawford-21">{description}</p>
			</Description>}
			{isBigOnHover && <DoubleDescription className='double-description'>
				<Description className="tile-desc">
					<p className="typo-strawford typo-strawford-21">{description}</p>
				</Description>
			</DoubleDescription>}
			<Reveal onInView className='text-bottom'>
				<p className="title typo-borna typo-borna-19">{text}</p>
			</Reveal>
			{/*{isTablet && isTileHovered && <SimpleIconButton className='arrow-red' color={colors.chilliRed} rotation={135} />}*/}
			<IconWrapper>
				<img className="icon2" src={icon} alt={iconAlt}/>
				{/*<img className="icon2-hover" src={iconHover} alt={iconHoverAlt}/>*/}
			</IconWrapper>
		</Tile>
	);
};

export default TileIconText;
