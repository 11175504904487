import React from 'react';
import styled from 'styled-components';
import {getCssSizeValue, getFontSize} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";
import Reveal from "@/animations/Reveal";

const Tile = styled.div`
	background-color: #F60002;
	height: ${getCssSizeValue(150, BREAKPOINTS.MOBILE)};
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: ${getCssSizeValue(16, BREAKPOINTS.DESKTOP)} ${getCssSizeValue(24, BREAKPOINTS.MOBILE)};
	p {
		color: ${({theme}) => theme.colors.white};
		line-height:${getFontSize(21, BREAKPOINTS.MOBILE)};
		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
			line-height: ${getFontSize(43.2, BREAKPOINTS.TABLET)};
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			line-height: ${getFontSize(43.2, BREAKPOINTS.DESKTOP)};
		}
	}
	@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
		height: ${getCssSizeValue(325, BREAKPOINTS.TABLET)};
		padding: ${getCssSizeValue(16, BREAKPOINTS.TABLET)} ${getCssSizeValue(24, BREAKPOINTS.TABLET)};
	}
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
		//height: ${getCssSizeValue(325, BREAKPOINTS.DESKTOP)};
		height: auto;
		padding: ${getCssSizeValue(45, BREAKPOINTS.DESKTOP)} ${getCssSizeValue(45, BREAKPOINTS.DESKTOP)};
	}
	@media(min-width: 1921px) {
		height: auto;
	}
`

const TileBig = ({data, isDesktop, isTablet, isMobile}) => {
	const text = data?.text || '';
	return (
		<Tile className="col-desktop-2 col-tablet-2 col-mobile-2">
			<Reveal onInView>
				<p className="typo-strawford typo-strawford-20">{text}</p>
			</Reveal>
		</Tile>
	);
};

export default TileBig;
