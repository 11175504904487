import React from 'react';
import styled from "styled-components";
import GridContainer from "@/components/common/GridContainer";
import {getCssSizeValue, getItemUrl, getPaddingsClassFromData} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";
import TileBig from "@/components/rulesTiles/TileBig";
import TileIconText from "@/components/rulesTiles/TileIconText";
import Reveal from "@/animations/Reveal";
import LinkButton from "@/components/common/Buttons/LinkButton";
import {BUTTON_VARIANTS} from "@/constants";
import {useIsDesktop, useIsMobile, useIsTablet} from "@/hooks/useDevice";

const StyledRulesTilesContainer = styled.div`
	background-color: ${({theme}) => theme.colors.richBlue};
`

const StyledHeaderAndButtonWrapper = styled(GridContainer)`
	margin-bottom: ${getCssSizeValue(55, BREAKPOINTS.MOBILE)};
	align-items: center;
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px){
		margin-bottom: ${getCssSizeValue(110, BREAKPOINTS.TABLET)};	
	}
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
		margin-bottom: ${getCssSizeValue(120, BREAKPOINTS.DESKTOP)}
	}
	p {
		color: ${({theme}) => theme.colors.antiflashWhite}
	}
`

const StyledButtonWrapper =styled.div`
	display: flex;
	justify-content: end;
	align-items: end;
`
const RulesTiles = ({data}) => {
	const header = data?.header || '';
	const rules = data?.rules || [];
	const buttonLink = data?.button || '';
	const paddings = getPaddingsClassFromData(data)
	const isDesktop = useIsDesktop();
	const isMobile = useIsMobile();
	const isTablet = useIsTablet();
	const slug = getItemUrl(buttonLink?.link?.link?.link || '');
	return (
		<StyledRulesTilesContainer className={paddings}>
			<StyledHeaderAndButtonWrapper>
				<Reveal onInView className="col-desktop-3 col-tablet-4 col-mobile-6">
					<p className="heading typo-borna typo-borna-10">
						{header}
					</p>
				</Reveal>
				{slug && <StyledButtonWrapper className="col-desktop-2" style={{ gridColumnStart: 15, gridColumnEnd: 17 }}>
					<LinkButton data={buttonLink} variant={BUTTON_VARIANTS.TERTIARY}/>
				</StyledButtonWrapper>}
			</StyledHeaderAndButtonWrapper>
			<GridContainer className="grid-5 grid-3-tablet no-gap">
				{rules.map((item, key) => {
					const type = item?.__typename || '';
					const isTileBig = type === 'TileBigRecord';
					const isTileIconText = type === 'TileIconTextRecord';
					return(
						<React.Fragment key={key}>
							{isTileBig && <TileBig isDesktop={isDesktop} isTablet={isTablet} isMobile={isMobile} data={item} index={key} />}
							{isTileIconText && <TileIconText isDesktop={isDesktop} isTablet={isTablet} isMobile={isMobile} data={item} index={key} buttonData={buttonLink} />}
						</React.Fragment>
					)
				})}
			</GridContainer>
		</StyledRulesTilesContainer>
	);
};

export default RulesTiles;
